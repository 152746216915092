<template>
  <div class="config-cost-details">
    <div class="config-cost-details__list standart-text">
      <div v-if="main.cost" class="config-cost-details__addon">
        <span class="config-cost-details__addon-name standart-title">
          {{ $t('prolong') + ' ' + main.name }}:
        </span>
        <span class="config-cost-details__addon-desc">
          {{ `${$n(main.cost, 'currency')} / ${periodText}` }}
        </span>
      </div>
      <div v-for="item in addons" :key="item.name" class="config-cost-details__addon">
        <span class="config-cost-details__addon-name standart-title"> {{ item.name }}: </span>
        <span class="config-cost-details__addon-desc">
          {{ getDesc(item) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BillMgrPeriods } from '@/models/BillMgr/periods';
export default {
  name: 'ConfigCostDetails',
  props: {
    total: {
      type: Number,
      required: true,
    },
    expireDate: {
      type: Date,
      required: true,
    },
    details: {
      type: Object,
      required: true,
      validator: val => val.addon && val.addon.length,
    },
    period: {
      type: [String, Number],
      default: 1,
      validator: val => Object.keys(BillMgrPeriods).includes(val.toString()),
    },
  },
  computed: {
    addons() {
      return this.details.addon
        .filter(i => parseFloat(i.cost) /* || parseFloat(i.period_cost)*/)
        .map(i => ({
          name: i.desc,
          value: i.value ? parseInt(i.value) : i.enumerationitem_name,
          addonvalue: parseInt(i.addonvalue),
          measure: i.measure,
          cost: parseFloat(i.cost),
          periodCost: parseFloat(i.period_cost),
        }));
    },
    main() {
      const main = this.details.main;
      return {
        cost: parseFloat(main.cost),
        name: main.desc,
      };
    },
    periodText() {
      const periodInt = parseInt(this.period);
      const periodInner = periodInt >= 12 ? periodInt / 12 : periodInt;
      const periodKey =
        periodInt >= 12
          ? 'year_c'
          : periodInt > 1
          ? BillMgrPeriods[this.period] + '_c'
          : BillMgrPeriods[this.period];
      return this.$tc(`period.${periodKey}`, periodInner);
    },
  },
  methods: {
    getDesc(item) {
      let str = `${item.value}`;
      if (item.measure) str += ` ${item.measure}`;
      if (item.addonvalue) str += ` (${this.$t('added')} ${item.addonvalue} ${item.measure})`;
      if (item.cost > 0) str += ` ${this.$t('for')}`;
      else str += `. ${this.$t('refund')}`;
      str += ` ${this.$n(Math.abs(item.cost), 'currency')}`;
      if (item.cost < 0) str += ` ${this.$t('forPeriod')}`;
      if (item.cost !== item.periodCost) {
        str += ` ${this.$t('till')} ${this.$d(this.expireDate, 'short')}`;
        if (item.cost && item.periodCost) str += `, ${this.$t('after')} - `;
        else if (!item.cost && item.periodCost) str += ` ${this.$t('for')} `;
        if (item.periodCost) {
          str += `${this.$n(item.periodCost, 'currency')} / ${this.periodText}`;
        }
      } else str += ` / ${this.periodText}`;
      return str;
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "added": "из них дополнительно",
      "for": "за",
      "till": "до",
      "after": "далее",
      "refund": "Вернём",
      "forPeriod": "за период",
      "prolong": "Продление"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.config-cost-details {
  &__addon {
    & + & {
      margin-top: 0.5em;
    }
  }
}
</style>
